import React, { memo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Pagination, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles(() => ({
  usersPageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  usersCount: {

  },
  root: {
    '& ul > li > button': {
      fontSize: '1.125rem',
    },
  },
  pageSizeSelect: {
    '& fieldset': {
      border: 0,
    },
    '& > div': {
      paddingLeft: '5px',
    },
  },
}));

const PaginationComponent = ({
  totalUsers, currentPage, handlePageClick, pageSize, setPageSize,
}) => {
  const classes = useStyles();

  let lastUserCount;
  const totalPages = Math.ceil(totalUsers / pageSize);

  lastUserCount = pageSize * currentPage;
  if (totalUsers < lastUserCount) {
    const diff = lastUserCount - totalUsers;
    lastUserCount -= diff;
  }

  const handleChange = (event) => {
    setPageSize(event.target.value);
  };

  return (
    <Box className={classes.usersPageWrapper}>
      <Typography variant="body1" className={classes.usersCount}>
        Rows per page:
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={pageSize}
        onChange={handleChange}
        className={classes.pageSizeSelect}
      >
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={75}>75</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <Pagination
        className={classes.root}
        count={totalPages}
        shape="rounded"
        page={currentPage}
        onChange={(e, val) => { handlePageClick(val); }}
      />
    </Box>
  );
};

PaginationComponent.propTypes = {
  totalUsers: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
};

export default memo(PaginationComponent, (prevProps, nextProps) => (
  prevProps.currentPage === nextProps.currentPage
));
