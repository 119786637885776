/* eslint-disable no-unused-vars */
import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _pickBy from 'lodash/pickBy';
import _intersection from 'lodash/intersection';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import { get, cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  Table, TableContainer,
} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import EnhancedTableBody from '../Table/EnhancedTableBody';
import Header from '../common/Header';
import { BLACK, MODERATE_DARK_GREY } from '../../stylesheets/colors';
import {
  CONTENT_TYPE_MAPPING, MATERIAL_TYPE_MAPPING, USER_ACCESS_MAPPING,
  CONTENT_TABLE_HEAD_ATTRIBUTES, AUTO_HIDE_DURATION,
  ROUTE_REPORT, USER_ACCESS, BULK_UPDATE_COMPONENT_ACCESS, REORDER_COMPONENTS,
} from '../../constants';
import CustomModal from '../common/CustomModal';
import FilterModal from '../../containers/ContentManagement/Components/FilterModal';
import RefreshConfirmationModal from '../../containers/ContentManagement/Components/RefreshConfirmationModal';
import TrainingMaterialModal from '../../containers/ContentManagement/Components/TrainingMaterialModal';
import CopyComponentModal from './CopyComponentModal';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import AlertReleaseInfo from './AlertReleaseInfo';
import EopComponentModal from './EopComponentModal';
import BulkUserAccessConfirmation from './BulkUserAccessConfirmation';
import useNotifications from '../../hooks/useNotifications';
import usePolling from '../../hooks/usePolling';
import { getErrorMessage } from '../../helpers/apiHelper';
import BulkUserAccessUpdateStatuses from './BulkUserAccessUpdateStatuses';
import { getReport, downloadFile } from '../../containers/common/apis';
import NoDataComponent from '../common/NoDataComponent';
import NoData from '../../assets/img/noChannel.svg';
import ReorderTMsconfirmationModal from './ReorderTMsconfirmationModal';
import ReorderTmsStatuses from './ReorderTmsStatuses';
import { SHOW_NOTIFICATION, REMOVE_NOTIFICATION } from '../../store/constants';
import ListHeader from './ComponentList/ListHeader';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '1.969rem 2rem 2rem 2rem',
  },
  titleRow: {
    fontSize: '1.25rem',
  },
  components: {
    fontSize: '1.75rem',
    fontWeight: 600,
    fontFamily: MCKINSEY_SANS,
    color: BLACK,
  },
  componentsBreakdown: {
    fontSize: '1.125rem',
    fontFamily: MCKINSEY_SANS,
    color: MODERATE_DARK_GREY,
    paddingRight: '2rem',
  },
  componentNumber: {
    fontWeight: 600,
    marginLeft: '2px',
  },
  infoIcon: {
    height: '12px',
    width: '12px',
    marginLeft: '3px',
  },
  tooltipHeader: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '4px',
    },
  },
  tooltipTitle: {
    fontSize: '1rem',
    fontFamily: MCKINSEY_SANS,
    fontWeight: 600,
  },
  tooltipSubtitle: {
    fontSize: '0.75rem',
    fontFamily: MCKINSEY_SANS,
    fontWeight: 600,
    marginTop: '0.313rem',
  },
  tooltipDescription: {
    fontSize: '0.75rem',
    lineHeight: 1.25,
    fontFamily: MCKINSEY_SANS,
  },
  order: {
    color: BLACK,
    fontSize: '1rem',
    fontWeight: 600,
    fontFamily: MCKINSEY_SANS,
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
  },
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    '& img': {
      width: '23rem',
      height: '15rem',
      marginBottom: '2rem',
    },
    '& span': {
      color: BLACK,
      fontSize: '2.5rem',
      fontWeight: 600,
      fontFamily: MCKINSEY_SANS,
      lineHeight: 1.2,
    },
  },
  pageHeader: {
    padding: '2rem 4rem 2rem 2rem',
  },
  tmModal: {
    width: '71.875rem',
  },
  nouserSection: {
    boxShadow: '0 0 1px 0 rgb(5 28 44 / 20%), 0 2px 4px -1px rgb(5 28 44 / 20%)',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
  },
});

const contentFilters = {
  type: {
    label: 'Type',
    options: CONTENT_TYPE_MAPPING,
  },
  user_access: {
    label: 'User Access',
    options: USER_ACCESS_MAPPING,
  },
  type_of_tm: {
    label: 'Type of Training Material',
    options: _omit(MATERIAL_TYPE_MAPPING, 'unspecified', 'lti'),
  },
};

const ContentManagementList = ({
  isLoading, tableData, metadata, setIsLoading, fetchContent, match, onCopyComponent,
  copiedComponentData, doceboUrl, openImportComponent, configId, copyProcessing, setTableData,
  assignOrRemoveEOPComponent, isDDAdmin, importInProgress, bulkUpdateComponentStatus,
  reorderComponents,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dropdownRef = useRef();
  const dispatch = useDispatch();

  const programId = get(match, 'params.programId');
  const programSubType = get(match, 'params.programSubType');
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showTmModal, setShowTmModal] = useState(false); // TM = Training Material
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState(cloneDeep(contentFilters));
  const [filtersClone, setFiltersClone] = useState(cloneDeep(contentFilters));
  const [pickedRow, setPickedRow] = useState({});
  const [assignedEop, setAssignedEop] = useState({});
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [updatedComponentsId, setUpdatedComponentsId] = useState([]);
  const [headerCheckBox, setHeaderCheckBox] = useState(false);
  const goToView = (row) => {
    history.push(`/${ROUTE_REPORT}/${programId}/${programSubType}?feature=view-course&id=${row.id}`);
  };
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);
  const [componentStatus, setComponentStatus] = useState('');
  const [showElement, setShowElement] = useState(false);
  const [enableReordering, setEnableReordering] = useState(false);
  const [confimationModal, setConfimationModal] = useState(false);
  const [disableTableOperations, setDisableTableOperations] = useState(false);
  const [copyOfData, setCopyOfData] = useState(false);
  const [orderingInProgress, setOrderingInProgress] = useState(false);
  const [reorderTransaction, setReorderTransaction] = useState(null);
  const goToBulkScorm = () => {
    history.push(`/${ROUTE_REPORT}/${programId}/${programSubType}?feature=bulk-scorm`);
  };
  const [openCopyComponent, setOpenCopyComponent] = useState(false);
  const [openEOPComponent, setOpenEOPComponent] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('order');
  const [modalState, setModalState] = useState('');
  const { notifyError } = useNotifications();
  const {
    transactionId: userAccessTransactionId,
    saveTransactionId: saveUserAccessTransactionId,
    progress, progressError,
  } = usePolling();
  const notification = useSelector((state) => state.notification);

  const storeComponentStatus = (status) => {
    setComponentStatus(status);
    setConfirmationModal(true);
    dropdownRef.current.handleClose();
  };

  const menuItems = [
    {
      key: 'published',
      title: 'Published',
      onClick: () => storeComponentStatus('published'),
    },
    {
      key: 'locked',
      title: 'Locked',
      onClick: () => storeComponentStatus('locked'),
    },
  ];

  const onDownloadLog = async (feature, transaction, q) => {
    try {
      const tid = transaction.transaction_id;
      const createdAt = transaction.created_at;
      await downloadFile(tid, configId, feature, createdAt, q);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const onViewStatus = useCallback((status) => {
    batchUpdates(() => {
      setInfoModal(true);
      setModalState(status);
    });
  }, []);

  const onInfoModalClose = () => {
    setInfoModal(false);
    setModalState('');
  };

  const getNoOfFilters = (filtersObj) => {
    let selectedFiltersCount = 0;
    Object.keys(filtersObj).forEach((filterType) => {
      Object.keys(filtersObj[filterType].options).forEach((selectedFilter) => {
        if (filtersObj[filterType].options[selectedFilter].is_selected) selectedFiltersCount += 1;
      });
    });
    return selectedFiltersCount;
  };

  const noOfSelectedFilters = getNoOfFilters(filters);
  const noOfSelectedFiltersInModal = getNoOfFilters(filtersClone);
  const havingSearchOrFilter = searchText || noOfSelectedFilters > 0;
  const renderedTableData = havingSearchOrFilter ? filteredTableData : tableData;

  useEffect(() => {
    setOrderBy('');
  }, [
    tableData,
  ]);

  useEffect(() => {
    if (notification !== null) {
      setTimeout(() => {
        dispatch({ type: REMOVE_NOTIFICATION });
      }, AUTO_HIDE_DURATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    const eopCourse = renderedTableData.length > 0
      && _find(renderedTableData, (o) => (o.eop === true));
    setAssignedEop(eopCourse);
  }, [renderedTableData]);

  const openCopyComponentModal = (row) => {
    setPickedRow(row);
    setOpenCopyComponent(true);
  };

  const openEOPComponentModal = (row) => {
    setPickedRow(row);
    setOpenEOPComponent(true);
  };

  useEffect(() => {
    if (!copyProcessing) {
      setOpenCopyComponent(false);
    }
  }, [copyProcessing]);

  const onRefresh = () => {
    if (searchText || noOfSelectedFilters) {
      setShowConfirmationModal(true);
    } else {
      setSearchText('');
      fetchContent({ isRefresh: true });
      setFilters(contentFilters);
      setFiltersClone(contentFilters);
      setFilteredTableData([]);
    }
  };

  const onOk = () => {
    setSearchText('');
    setShowConfirmationModal(false);
    fetchContent({ isRefresh: true });
    setFilters(contentFilters);
    setFiltersClone(contentFilters);
    setFilteredTableData([]);
  };

  const handleModalClose = () => {
    setFiltersClone(filters);
    setShowFilterModal(false);
  };

  const assignOrUnassignEOP = (courseId, eopValue) => {
    assignOrRemoveEOPComponent(courseId, { eop: eopValue });
    setOpenEOPComponent(false);
  };

  const getSelected = useCallback((valType) => Object.keys(_pickBy(filtersClone[valType]?.options,
    (obj) => obj?.is_selected === true)), [filtersClone]);

  const onSearch = useCallback((searchInput) => {
    const text = searchInput ? searchInput.toLowerCase() : searchInput;
    const filteredData = [];
    tableData.forEach((obj) => {
      if (obj?.name?.toLowerCase().includes(text)
        || obj?.code?.toLowerCase().includes(text)
        || obj?.docebo_id?.includes(text)) {
        filteredData.push(obj);
      }
    });
    return filteredData;
  }, [tableData]);

  const handleApplySearchFilters = useCallback(({ searchInput, applyFilters }) => {
    setIsLoading(true);
    let filteredData = [];

    if (applyFilters) {
      setOrderBy('');
      setFilters(filtersClone);
      setShowFilterModal(false);
    } else {
      setSearchText(searchInput);
    }

    if (searchInput) {
      filteredData = onSearch(searchInput);
    }
    const selectedFiltersLength = getNoOfFilters(filtersClone);
    const usedData = searchInput ? filteredData : tableData;

    if (selectedFiltersLength && usedData.length) {
      filteredData = [];

      const selectedType = getSelected('type');
      const selectedUserAccess = getSelected('user_access');
      const selectedTmType = getSelected('type_of_tm');

      filteredData = _filter(usedData,
        (row) => (
          (selectedType.length > 0 ? selectedType.includes(row?.type?.value) : true)
          && (selectedUserAccess.length > 0
            ? selectedUserAccess.includes(row?.user_access?.value) : true)
          && (selectedTmType.length > 0
            ? _intersection(row?.unique_tm, selectedTmType).length > 0 : true)
        ));
    }

    setFilteredTableData(filteredData);
    setIsLoading(false);
  }, [filtersClone, onSearch, setIsLoading, getSelected, tableData]);

  const selectAllRow = (checked) => {
    if (checked) {
      setSelectedComponents(renderedTableData);
    } else {
      setSelectedComponents([]);
    }
  };

  const handleCheckboxChange = (ev, componentData) => {
    if (ev.target.checked) {
      const updatedArray = [...selectedComponents, componentData];
      setSelectedComponents(updatedArray);
      if (updatedArray.length === renderedTableData.length) {
        setHeaderCheckBox(true);
      }
    } else {
      const filteredArray = selectedComponents.filter(
        (item) => item.id !== componentData.id,
      );
      setSelectedComponents(filteredArray);
      setHeaderCheckBox(false);
    }
  };

  const updateFilters = useCallback(({ filterType, selectedFilter }) => {
    const updatedFilters = cloneDeep(filtersClone);
    // eslint-disable-next-line max-len
    updatedFilters[filterType].options[selectedFilter].is_selected = !updatedFilters[filterType].options[selectedFilter].is_selected;
    setFiltersClone(updatedFilters);
  }, [filtersClone]);

  const removeFilterFromChip = ({ filterType, selectedFilter }) => {
    const updatedFilters = cloneDeep(filtersClone);
    updatedFilters[filterType].options[selectedFilter].is_selected = false;
    setFiltersClone(updatedFilters);
  };

  const handleClearFilters = () => {
    setIsLoading(true);
    setFilters(contentFilters);
    setFiltersClone(contentFilters);
    setSearchText('');
    setFilteredTableData([]);
    setIsLoading(false);
  };

  const getTooltipJSX = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span className={classes.tooltipTitle}>
        Types of access (Docebo)
      </span>
      <span className={classes.tooltipSubtitle}>
        Published:
      </span>
      <span className={classes.tooltipDescription}>
        Component is available and searchable for enrolled users
      </span>
      <span className={classes.tooltipSubtitle}>
        Locked:
      </span>
      <span className={classes.tooltipDescription}>
        Component is unavailable for anyone, including assigned
        users but is always available in Admin areas.
      </span>
    </div>
  );

  const onTmSelect = (selectedRow) => {
    setSelectedContent(selectedRow);
    setShowTmModal(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getContentMetadata = () => {
    let meteDataJsx;
    if (!tableData.length
      || ((searchText || noOfSelectedFilters > 0) && !filteredTableData.length)) {
      meteDataJsx = (
        <span className={classes.components}>
          Showing Results: 0 components
        </span>
      );
    } else if ((searchText || noOfSelectedFilters > 0) && filteredTableData.length) {
      meteDataJsx = (
        <span className={classes.components}>
          {`Showing Results: ${filteredTableData.length} of ${tableData.length} components`}
        </span>
      );
    } else {
      meteDataJsx = (
        <div className={classes.componentsBreakdown}>
          (E-Learning Components:
          <span className={classes.componentNumber}>
            {' '}
            {metadata.eLearningComponents}
          </span>
          {' '}
          ; Workshops:
          <span className={classes.componentNumber}>{metadata.workshopComponents}</span>
          )
        </div>
      );
    }
    return meteDataJsx;
  };

  const initiateBulkUserAccess = async () => {
    try {
      setLoading(true);
      const componentList = _map(selectedComponents, 'docebo_course_id');
      const resp = await bulkUpdateComponentStatus({
        status: USER_ACCESS[componentStatus].value,
        program_id: programId,
        component_list: componentList,
      });
      if (resp.data.success === true) {
        saveUserAccessTransactionId(resp?.data?.transaction_id);
      } else {
        notifyError(resp?.data?.message);
      }
    } catch (e) {
      notifyError(getErrorMessage(e));
    } finally {
      setLoading(false);
      setConfirmationModal(false);
    }
  };

  useEffect(() => {
    if (progressError !== null) {
      setDisabledActions(false);
      notifyError(progressError);
    }
    if (progress?.done) {
      batchUpdates(() => {
        setUpdatedComponentsId(_map(selectedComponents, 'docebo_course_id'));
        setDisabledActions(false);
        setShowElement(true);
        fetchContent({ isRefresh: true });
        setSelectedComponents([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, progressError]);

  useEffect(() => {
    if (showElement) {
      setTimeout(() => {
        setShowElement(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showElement]);

  const cancelReordering = () => {
    setTableData(copyOfData);
    setEnableReordering(false);
    setDisableTableOperations(false);
  };

  const presetReordering = () => {
    handleClearFilters();
    setOrder('');
    setDisableTableOperations(true);
    setCopyOfData(tableData);
    setEnableReordering(true);
    setSelectedComponents([]);
    setHeaderCheckBox(false);
  };

  const saveReorderComponents = async () => {
    try {
      setOrderingInProgress(true);
      const payload = {
        program_id: programId,
        courses: _map(tableData, (o) => ({ id: o.id, course_code: o.course_code })),
      };
      const resp = await reorderComponents(payload);
      if (resp.data.success === true) {
        setReorderTransaction(resp.data.transaction_id);
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'success', message: 'Changes saved successfully.' } });
        fetchContent({ isRefresh: false });
      } else {
        setReorderTransaction(resp.data.transaction_id);
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'error', message: resp.data.message || 'Failed to re-order components.' } });
        cancelReordering();
      }
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'error', message: err.response.data.message || 'Failed to re-order components.' } });
      } else {
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'serverError', message: `Server Error: ${getErrorMessage(err)}.` } });
      }
      setReorderTransaction(err?.response?.data?.transaction_id);
      cancelReordering();
    } finally {
      setOrderingInProgress(false);
      setEnableReordering(false);
      setConfimationModal(false);
      setDisableTableOperations(false);
    }
  };

  const saveReordering = () => {
    saveReorderComponents();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const reorderComponentTableData = (result) => {
    if (!result.destination) {
      return;
    }
    const movedItems = reorder(
      tableData,
      result.source.index,
      result.destination.index,
    );
    setTableData(movedItems);
  };

  const compoentText = metadata.totalComponents > 1 ? 'components' : 'component';

  const componentCount = tableData.length ? <Grid item className={classes.components}>{`${metadata.totalComponents} ${compoentText}`}</Grid> : null;

  return (
    <>
      {userAccessTransactionId && Object.keys(progress).length > 0 && !infoModal ? (
        <AlertReleaseInfo
          progress={progress}
          showElement={showElement}
          progressMessage={`User access update for ${progress?.count} components is in progress`}
          withErrorsMessage="User access was updated with errors."
          failedMessage="Failed to update User access"
          successMessage="User Access updated successfully"
          onViewStatus={onViewStatus}
          setShowElement={setShowElement}
        />
      ) : null}
      {notification && (
        <ReorderTmsStatuses
          transactionId={reorderTransaction}
          statusVariant={notification}
          showElement={notification !== null}
          closeNotification={() => dispatch({ type: REMOVE_NOTIFICATION })}
          onDownloadLog={(tId, q) => onDownloadLog(REORDER_COMPONENTS, tId, q)}
          getReport={getReport}
          forComponents
        />
      )}
      <FilterModal
        open={showFilterModal}
        onClose={handleModalClose}
        filters={filtersClone}
        updateFilters={updateFilters}
        handleClearAll={() => { setFiltersClone(contentFilters); }}
        handleApplyAll={() => {
          handleApplySearchFilters({ searchInput: searchText, applyFilters: true });
        }}
        noOfSelectedFiltersInModal={noOfSelectedFiltersInModal}
        removeFilterFromChip={removeFilterFromChip}
      />
      <CustomModal
        open={showTmModal}
        onClose={() => { setShowTmModal(false); }}
        modalStyle={classes.tmModal}
      >
        <TrainingMaterialModal
          data={selectedContent}
          doceboUrl={doceboUrl}
        />
      </CustomModal>
      <CustomModal open={showConfirmationModal} onClose={() => { setShowConfirmationModal(false); }} breakpoint="sm">
        <RefreshConfirmationModal
          onClose={() => { setShowConfirmationModal(false); }}
          onOk={() => { onOk(); }}
        />
      </CustomModal>
      <Paper className={classes.wrapper}>
        <Header heading="Content Management" subHeading="Manage Components within this Learning Plan." className={classes.pageHeader}>
          <Box>
            {!isDDAdmin && (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ maxHeight: '3.25rem' }}
                  fontSize="medium"
                  onClick={goToBulkScorm}
                  disabled={enableReordering}
                >
                  Bulk SCORM Full Re-release
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
                  fontSize="medium"
                  onClick={openImportComponent}
                  disabled={importInProgress || enableReordering}
                >
                  Import a Component Copy
                </Button>
              </>
            )}
          </Box>
        </Header>

        {isLoading ? <LoadingCircle /> : (
          <div className={classes.bodyContainer}>
            <ListHeader
              searchText={searchText}
              noOfSelectedFilters={noOfSelectedFilters}
              componentCount={componentCount}
              getContentMetadata={getContentMetadata}
              onRefresh={onRefresh}
              metadata={metadata}
              disableTableOperations={disableTableOperations}
              handleApplySearchFilters={handleApplySearchFilters}
              setShowFilterModal={setShowFilterModal}
              handleClearFilters={handleClearFilters}
              isDDAdmin={isDDAdmin}
              enableReordering={enableReordering}
              presetReordering={presetReordering}
              setConfimationModal={setConfimationModal}
              cancelReordering={cancelReordering}
              dropdownRef={dropdownRef}
              menuItems={menuItems}
              selectedComponents={selectedComponents}
            />
            <div style={{ maxWidth: '100%', marginTop: '0.8rem', height: '100%' }}>
              {renderedTableData.length ? (
                <Box>
                  <TableContainer style={{ maxHeight: 400 }}>
                    <Table aria-label="simple table" padding="normal" stickyHeader>
                      <EnhancedTableHeader
                        headCells={CONTENT_TABLE_HEAD_ATTRIBUTES}
                        getTooltipJSX={getTooltipJSX}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        checkboxEnable={!enableReordering}
                        checkboxHandler={selectAllRow}
                        defaultCheckBoxValue={headerCheckBox}
                        disableAllSorting={disableTableOperations}
                        defaultHeaderCell={!!enableReordering}
                      />
                      <EnhancedTableBody
                        rows={renderedTableData}
                        onTmSelect={onTmSelect}
                        goToView={goToView}
                        order={order}
                        orderBy={orderBy}
                        openCopyComponentModal={openCopyComponentModal}
                        openEOPComponentModal={openEOPComponentModal}
                        copiedComponentData={copiedComponentData}
                        isDDAdmin={isDDAdmin}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedComponents={selectedComponents}
                        updatedComponentsId={updatedComponentsId}
                        enableReordering={enableReordering}
                        onDragEnd={reorderComponentTableData}
                      />
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <>
                  {tableData?.length > 0 ? (
                    <Paper elevation="3" style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className={classes.noContent}>
                        <img src={NoData} alt="no-channel" />
                        <Typography
                          variant="h4"
                          component="h1"
                          align="center"
                          style={{ fontWeight: '900', fontSize: '3rem' }}
                        >
                          Oops!
                        </Typography>
                        <Typography
                          variant="h4"
                          component="h1"
                          align="center"
                          style={{ fontWeight: '900', fontSize: '3rem' }}
                        >
                          Could not find the component you were looking for.
                        </Typography>
                      </div>
                    </Paper>
                  ) : (
                    <Box className={classes.nouserSection}>
                      <NoDataComponent
                        imgSrc={NoData}
                        primaryText="This learning plan has no components yet."
                        secondaryText="Please contact a Course Production member for further assistance."
                      />
                    </Box>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Paper>
      <CopyComponentModal
        copyProcessing={copyProcessing}
        loadingText="Copying component shell…"
        open={openCopyComponent}
        programId={programId}
        configId={configId}
        onClose={() => setOpenCopyComponent(false)}
        data={pickedRow}
        onCopyComponent={onCopyComponent}
      />
      {
        openEOPComponent && (
          <EopComponentModal
            open={openEOPComponent}
            onClose={() => setOpenEOPComponent(false)}
            row={pickedRow}
            assignedEop={assignedEop}
            onConfirm={assignOrUnassignEOP}
          />
        )
      }
      {
        confirmationModal && (
          <BulkUserAccessConfirmation
            open={confirmationModal}
            onClose={() => setConfirmationModal(false)}
            loading={loading}
            mainContent="All the components will update automatically with the new full re-release.
              Actions on training materials will be disabled until the re-release is complete."
            status={componentStatus}
            onConfirm={initiateBulkUserAccess}
          />
        )
      }
      {
        userAccessTransactionId && infoModal && (
          <BulkUserAccessUpdateStatuses
            transactionId={userAccessTransactionId}
            modalState={modalState}
            open={infoModal}
            onClose={onInfoModalClose}
            onDownloadLog={(tId, q) => onDownloadLog(BULK_UPDATE_COMPONENT_ACCESS, tId, q)}
            getReport={getReport}
            progressData={progress}
            progressError={progressError}
          />
        )
      }
      {
        confimationModal && (
          <ReorderTMsconfirmationModal
            open={confimationModal}
            loading={orderingInProgress}
            onClose={() => setConfimationModal(false)}
            onConfirm={saveReordering}
            isComponentList
          />
        )
      }
    </>
  );
};

ContentManagementList.defaultProps = {
  copiedComponentData: {
    new_component_title: '',
    new_component_code: '',
  },
};

ContentManagementList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired,
  metadata: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  fetchContent: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  copiedComponentData: PropTypes.shape({
    new_component_title: PropTypes.string,
    new_component_code: PropTypes.string,
  }),
  onCopyComponent: PropTypes.func.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  openImportComponent: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  copyProcessing: PropTypes.bool.isRequired,
  assignOrRemoveEOPComponent: PropTypes.func.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  importInProgress: PropTypes.bool.isRequired,
  bulkUpdateComponentStatus: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  reorderComponents: PropTypes.func.isRequired,
};

export default ContentManagementList;
