import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import _get from 'lodash/get';
import _each from 'lodash/each';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel, ArrowRightAlt } from '@material-ui/icons';
import SettingValueCell from './SettingValueCell';
import StagesMap from '../StageBadge/constants';
import tooltipMessages from './tooltipMessages';
import CustomModal from '../common/CustomModal';
import ConfigChangeModal from '../Modals/ConfigChangeModal';
import LaunchedModal from '../Modals/LaunchedModal';
import { STAGE_LAUNCHED } from '../../constants';
import { getPortfolioOfferingProgram } from '../../containers/common/apis';
import { toArrayOfObject } from '../../helpers/utils';

const defaultSettingsValue = {
  stage: '',
  dd_analyst: '',
};

const validationSchema = yup.object({
  stage: yup.string(),
  dd_analyst: yup.string().nullable(),
});

const DetailsView = ({
  settings, isEdit, onSubmit, stages, onEdit, onCancel, isAdmin, push,
}) => {
  const [configModal, setConfigModal] = useState(false);
  const [showLaunchedModal, setLaunchedModal] = useState(false);
  const [redirectCase, setRedirectCase] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [offeringOptions, setOfferingOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const defaultData = settings?.program_id ? settings : defaultSettingsValue;

  const {
    values, handleSubmit, errors, touched, setFieldValue, resetForm,
  } = useFormik({
    initialValues: { ...defaultData },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (payload, bag) => {
      const required_additional_field_data = payload.additional_fields.filter((item) => item.title !== 'Program' && !item.value);
      if (required_additional_field_data.length) {
        const errorObj = {};
        _each(required_additional_field_data, (element) => {
          errorObj[element.title] = 'This is required field';
        });
        bag.setErrors(errorObj);
      } else {
        onSubmit(payload);
      }
    },
  });
  // eslint-disable-next-line max-len
  const getadditionalFieldValue = (field) => (values?.additional_fields?.find((item) => item.title === field).value);

  const portfolioValue = getadditionalFieldValue('Portfolio');
  const offeringValue = getadditionalFieldValue('Offering');

  const fetchPortfolioOfferingProgram = async (data) => {
    const response = await getPortfolioOfferingProgram(data);
    const optionArray = _get(response, 'data.data', []);
    if (!data) {
      setPortfolioOptions(toArrayOfObject(optionArray));
    } else if (Object.keys(data).length === 1) {
      setOfferingOptions(toArrayOfObject(optionArray));
    } else {
      setProgramOptions(toArrayOfObject(optionArray));
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchPortfolioOfferingProgram();
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && portfolioValue) {
      fetchPortfolioOfferingProgram({ portfolio: portfolioValue });
    }
  }, [isEdit, portfolioValue]);

  useEffect(() => {
    if (isEdit && portfolioValue && offeringValue) {
      fetchPortfolioOfferingProgram({ portfolio: portfolioValue, offering: offeringValue });
    }
  }, [isEdit, portfolioValue, offeringValue]);

  const cancelEdit = () => {
    resetForm();
    onCancel();
  };

  const openRedirectModal = (k) => {
    setConfigModal(true);
    setRedirectCase(k);
  };

  useEffect(() => {
    localStorage.setItem('currentStage', settings.stage);
  }, [settings.stage]);

  const handleStatusChange = (name, value) => {
    setSelectedStatus(value);
    const stage = localStorage.getItem('currentStage');
    if ((value === STAGE_LAUNCHED || stage === STAGE_LAUNCHED) && value !== stage) {
      setLaunchedModal(true);
    } else {
      setFieldValue(name, value);
      localStorage.setItem('currentStage', value);
      setSelectedStatus('');
    }
  };

  const handleDropdownChange = (name, val) => {
    let field_Data = values.additional_fields.map(
      (obj) => (obj.title === name ? { ...obj, value: val } : obj),
    );
    if (name === 'Portfolio') {
      field_Data = field_Data.map(
        (obj) => (obj.title !== 'Portfolio' ? { ...obj, value: null } : obj),
      );
    } else if (name === 'Offering') {
      field_Data = field_Data.map(
        (obj) => (obj.title === 'Program' ? { ...obj, value: null } : obj),
      );
    }
    setFieldValue('additional_fields', field_Data);
  };

  const handleProgramChange = (name, event) => {
    setFieldValue(name, event.target.checked);
  };

  const onConfirm = () => {
    setFieldValue('stage', selectedStatus);
    localStorage.setItem('currentStage', selectedStatus);
    setLaunchedModal(false);
    setSelectedStatus('');
  };

  const onClose = () => {
    setLaunchedModal(false);
    setSelectedStatus('');
  };

  const ddAnalyst = values?.dd_analyst ? values.dd_analyst.split(',').map((el) => el.trim()) : [];
  const cp_poc = values?.cp_poc ? values.cp_poc.split(',').map((el) => el.trim()) : [];
  const course_delivery_analyst = values?.course_delivery_analyst ? values.course_delivery_analyst.split(',').map((el) => el.trim()) : [];
  const offering_owner = values?.offering_owner ? values.offering_owner.split(',').map((el) => el.trim()) : [];

  const portfolio = values?.additional_fields ? values?.additional_fields.filter((item) => item.title === 'Portfolio')[0].value : '';
  const offering = values?.additional_fields ? values?.additional_fields.filter((item) => item.title === 'Offering')[0].value : '';
  const program = values?.additional_fields ? values?.additional_fields.filter((item) => item.title === 'Program')[0].value : '';
  const type_of_program = [
    {
      label: 'Academy GO',
      value: values?.is_academy_go,
      key: 'is_academy_go',
    },
    {
      label: 'GSR',
      value: values?.is_gsr,
      key: 'is_gsr',
    },
    {
      label: 'SKILLS',
      value: values?.is_skill,
      key: 'is_skill',
    },
  ];
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-header">
        <div>
          <span className="form-header-title">Details &amp; Status</span>
        </div>
        <div>
          {!isEdit && (
          <Button color="primary" variant="contained" onClick={() => onEdit()}>
            Edit
          </Button>
          )}
          {isEdit && (
          <>
            <Button color="primary" variant="outlined" onClick={() => cancelEdit()}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save Changes
            </Button>
          </>
          )}
        </div>
      </div>
      <div className="form-content">
        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="Configuration ID"
              value={values.config_id}
              tooltipMessage={tooltipMessages.CONFIG_ID}
            >
              {isEdit && isAdmin && (
              <div className="fld-value mt10">
                <Alert
                  severity="error"
                  className="w100"
                  icon={<Cancel fontSize="inherit" className="dangertext" />}
                  action={(
                    <Button
                      className="danger"
                      size="small"
                      variant="contained"
                      onClick={() => openRedirectModal(1)}
                    >
                      Proceed Anyway
                      <ArrowRightAlt />
                    </Button>
                      )}
                >
                  Modifying the Config ID is not recommended.
                </Alert>
              </div>
              )}
            </SettingValueCell>

            <SettingValueCell
              label="Docebo Learning Plan ID"
              value={values.docebo_lp_id}
              tooltipMessage={tooltipMessages.DOCEBO_LPID}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              name="lineage"
              label="Origin"
              hasHTML
              value={(values.replicated_program_id ? `Replicated from <strong>${values.lineage}</strong>` : values.lineage) || 'N/A'}
              redirectTo={values.replicated_program_id}
              tooltipMessage={tooltipMessages.LINEAGE}
              isEditRedirect={isEdit}
              openRedirectModal={openRedirectModal}
            />

            <SettingValueCell
              label="Status"
              isEdit={isEdit}
              value={values?.stage ? StagesMap[values.stage] : 'N/A'}
              tooltipMessage={tooltipMessages.STATUS}
              handleChange={handleStatusChange}
              fieldType="chip"
              name="stage"
              options={stages}
            />
          </div>
          <div className="form-row">
            <SettingValueCell
              label="Type of Program"
              isEdit={isEdit}
              value={values?.is_academy_go || values?.is_gsr || values?.is_skill ? type_of_program : 'N/A'}
              tooltipMessage={tooltipMessages.PROGRAM}
              handleChange={handleProgramChange}
              fieldType="checkbox"
              name="type_of_program"
              options={type_of_program}
              valueType={values?.is_academy_go || values?.is_gsr || values?.is_skill ? 'multi-chip' : 'text'}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="DD Analyst"
              name="dd_analyst"
              value={ddAnalyst}
              valueType="chip"
              tooltipMessage={tooltipMessages.DD_ANALYST}
              isEdit={isEdit}
              fieldType="chipinput"
              hasError={touched.dd_analyst && !!errors.dd_analyst}
              errorText={touched.dd_analyst && errors.dd_analyst}
              handleChange={setFieldValue}
            />
            <SettingValueCell
              label="Course Production PoC"
              name="cp_poc"
              value={cp_poc}
              valueType="chip"
              tooltipMessage={tooltipMessages.CP_POC}
              isEdit={isEdit}
              fieldType="chipinput"
              hasError={touched.cp_poc && !!errors.cp_poc}
              errorText={touched.cp_poc && errors.cp_poc}
              handleChange={setFieldValue}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="Course Delivery Analyst"
              name="course_delivery_analyst"
              value={course_delivery_analyst}
              valueType="chip"
              tooltipMessage={tooltipMessages.COURSE_DELIVERY_ANALYST}
              isEdit={isEdit}
              fieldType="chipinput"
              hasError={touched.course_delivery_analyst && !!errors.course_delivery_analyst}
              errorText={touched.course_delivery_analyst && errors.course_delivery_analyst}
              handleChange={setFieldValue}
            />
            <SettingValueCell
              label="Offering Owner"
              name="offering_owner"
              value={offering_owner}
              valueType="chip"
              isEdit={isEdit}
              fieldType="chipinput"
              hasError={touched.offering_owner && !!errors.offering_owner}
              errorText={touched.offering_owner && errors.offering_owner}
              handleChange={setFieldValue}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="Portfolio"
              name="Portfolio"
              isRequired
              value={portfolio}
              valueType="text"
              isEdit={isEdit}
              fieldType="dropdown"
              hasError={!!errors.Portfolio}
              errorText={errors.Portfolio}
              handleChange={handleDropdownChange}
              options={portfolioOptions}
            />
            <SettingValueCell
              label="Offering"
              name="Offering"
              isRequired
              value={offering}
              valueType="text"
              isEdit={isEdit}
              fieldType="dropdown"
              hasError={!!errors.Offering}
              errorText={errors.Offering}
              handleChange={handleDropdownChange}
              options={offeringOptions}
            />
          </div>
          <div className="form-row program">
            <SettingValueCell
              label="Program"
              name="Program"
              value={program}
              valueType="text"
              isEdit={isEdit}
              fieldType="dropdown"
              hasError={touched.program && !!errors.program}
              errorText={touched.program && errors.program}
              handleChange={handleDropdownChange}
              options={programOptions}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-row">
            <SettingValueCell
              label="Learning Plan Components Available to Copy"
              name="is_locked"
              value={values?.is_locked}
              valueType="switch"
              isEdit={isEdit}
              fieldType="switchinput"
              hasError={touched.is_locked && !!errors.is_locked}
              errorText={touched.is_locked && errors.is_locked}
              handleChange={setFieldValue}
            />
          </div>
        </div>
      </div>

      <CustomModal open={configModal} onClose={() => setConfigModal(false)} breakpoint="sm">
        <ConfigChangeModal
          onClose={() => setConfigModal(false)}
          onConfirm={(e) => {
            handleSubmit(e);
            setTimeout(() => {
              const link = redirectCase !== 1 ? `/report/${settings.replicated_program_id}/overview/` : `/report/${settings.program_id}/edit_program_code/`;
              push(link);
            }, 100);
          }}
        />
      </CustomModal>

      <CustomModal open={showLaunchedModal} onClose={onClose} breakpoint="sm">
        <LaunchedModal
          incoming={selectedStatus === STAGE_LAUNCHED}
          onConfirm={onConfirm}
          onClose={onClose}
          selectedStatus={selectedStatus}
        />
      </CustomModal>

    </form>
  );
};

DetailsView.defaultProps = {
  isEdit: false,
  isAdmin: false,
  stages: [],
};

DetailsView.propTypes = {
  settings: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  stages: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

export default DetailsView;
