import {
  SET_LOADING,
  CLEAR_ALERTBAR,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILED,
  SET_USER_DETAILS_SUCCESS,
  SET_SNACKBAR,
  FETCH_LANGUAGES,
  FETCH_CLIENTID,
  FETCH_CLIENTID_SUCCESS,
  FETCH_CLIENTID_FAILED,
  UPDATE_USER_DETAILS_STORE,
  SET_PLATFORM_DETAILS_LOADING,
  SAVE_USER_DETAILS,
  TOGGLE_USER_DETAIL_ALERTBAR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CLEAR_TRANSACTION_ID,
} from '../../constants';

export function getUserDetails(data) {
  return {
    type: FETCH_USER_DETAILS,
    data,
  };
}

export function getUserDetailsSuccess(results) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    results,
  };
}
export function setUserDetailsSuccess(results) {
  return {
    type: SET_USER_DETAILS_SUCCESS,
    results,
  };
}

export function getUserDetailsFailure(response) {
  return {
    type: FETCH_USER_DETAILS_FAILED,
    response,
  };
}

export function getUserCreateFailure(response) {
  return {
    type: CREATE_USER_ERROR,
    response,
  };
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    payload,
  };
}

export function setPlatformDetailsLoader(payload) {
  return {
    type: SET_PLATFORM_DETAILS_LOADING,
    payload,
  };
}

export function clearAlertBar() {
  return { type: CLEAR_ALERTBAR };
}

export function setSnackbar(payload) {
  return {
    type: SET_SNACKBAR,
    payload,
  };
}

export function fetchLanguages(payload) {
  return {
    type: FETCH_LANGUAGES,
    payload,
  };
}

export function fetchClientIds(payload) {
  return {
    type: FETCH_CLIENTID,
    payload,
  };
}

export function fetchClientdSuccess(payload) {
  return {
    type: FETCH_CLIENTID_SUCCESS,
    payload,
  };
}

export function fetchClientdFailure(payload) {
  return {
    type: FETCH_CLIENTID_FAILED,
    payload,
  };
}

export function updateUserDetailsStore(payload) {
  return {
    type: UPDATE_USER_DETAILS_STORE,
    payload,
  };
}

export function saveUserDetails(payload) {
  return {
    type: SAVE_USER_DETAILS,
    payload,
  };
}

export function toggleAlertBar(payload) {
  return {
    type: TOGGLE_USER_DETAIL_ALERTBAR,
    payload,
  };
}

export function createUser(payload) {
  return {
    type: CREATE_USER,
    payload,
  };
}

export function createUserSuccess(payload) {
  console.log(payload);
  return {
    type: CREATE_USER_SUCCESS,
    payload,
  };
}

export function createUserError(response) {
  return {
    type: CREATE_USER_ERROR,
    response,
  };
}

export function clearTransactionId() {
  return {
    type: CLEAR_TRANSACTION_ID,
  };
}
